import { Quill } from "@vueup/vue-quill";

const BlockEmbed = Quill.import("blots/block/embed");

export class ImageUploadBlot extends BlockEmbed {
  static blotName = "imageUpload";
  static tagName = "div";
  static className = "image-uploading-container";

  static create(value: any) {
    const node = super.create();
    node.setAttribute("data-id", value.id);
    node.classList.add("image-uploading-container");

    // Create the progress bar overlay
    const progressBar = document.createElement("div");
    progressBar.classList.add("progress-bar");
    node.appendChild(progressBar);


    // Create the image element
    const img = document.createElement("img");
    img.setAttribute("src", value.src || "");
    img.classList.add("image-uploading");

    node.appendChild(img);



    return node;
  }

  static value(node: any) {
    const img = node.querySelector("img");
    return {
      src: img ? img.getAttribute("src") : "",
      id: node.getAttribute("data-id"),
    };
  }
}

